import React from "react"
//import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"

import SearchFilter from "../../components/parts/chart/search-filter"
import BarStackGrouped from "../../components/parts/chart/bar-stack-grouped"
import BarStacked from "../../components/parts/chart/bar-stacked"
import MakerShare from "../../components/parts/chart/maker-share"
import SankyModal from "../../components/parts/modal"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false,
      s02_2_1_3: false,
      s02_2_1_4: false
    }

    this.searchInit = {
      filter1Init: 1,
      filter2Init: 1,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openImageBox = this.openImageBox.bind(this)
    this.closeImageBox = this.closeImageBox.bind(this)
  }

  openModal(e) {
    this.setState({modalOpen : true})
  }
  closeModal(e) {
    this.setState({modalOpen : false})
  }

  openImageBox(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeImageBox(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>IEA NZEにおけるエネルギーバランス・フロー概要（2050年）</h2>
              </div>
              <div className={chartStyles.chartImageWrapper}>
                <button className={styles.modalLink} onClick={this.openModal}>
                  <img src="/images/chart/sanky-2.svg" alt="" width="100%" height="auto" />
                </button>
                <SankyModal
                    open={this.state.modalOpen}
                    close={this.closeModal}
                    src={"/images/chart/sanky-2.svg"}
                    alt={"IEA NZEにおけるエネルギーバランス・フロー概要（2050年）"}
                    style={{width : '100%', height: 'auto'}}
                  />
              </div>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/net-zero-by-2050" target="_blank" rel="noreferrer noopener">IEA "Net Zero by 2050"</a>（2021年5月）より作成</p>
                <p className={chartStyles.chartExplain}>IEAネットゼロシナリオにおける2050年の世界全体の一次エネルギー供給から最終エネルギー消費までのエネルギーの流れを示したもの。<br />
各項目の投入と産出の差分を損失として集計しており、原典に記載されている情報の制約により合計が一致しない項目もある。</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarStackGrouped id="typeFuture" chartData="typeFuture" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>水素利用見通し（鉄鋼）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openImageBox} data-name={"s02_2_1_3"}>
                  <img src={'../../images/chart/hydrogen-stacked-bar-grouped-1.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_2_1_3}
                  close={this.closeImageBox}
                  name={"s02_2_1_3"}
                  src={'/images/chart/hydrogen-stacked-bar-grouped-1.png'}
                  alt="水素利用見通し（鉄鋼）"
                  width={1122}
                  height={533} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/energy-technology-perspectives-2020" target="_blank" rel="noreferrer noopener">IEA, ”Energy Technology Perspectives 2020”</a>（2020年9月）</p>
                <p className={chartStyles.chartExplain}>水素還元製鉄を含む製鉄方法別の粗鋼生産量（世界）の実績、見通しを表示。<br />
                <br />
                STEPS（Stated Policy Scenario）：公表政策シナリオ<br />
                SDS（Sustainable Development Scenario）：持続可能な開発シナリオ<br />
                DRI：direct reuced iron、SR：smelting reduction、BF：blast furnace<br />
                Commercialと記載のあるものは、従来の製鉄方法を意味する。特記なき場合CCUSは行わない。<br />
                Innovative BF with CCUSの例として、COURSE50、IGAR、3D projects(s)がある。<br />
                Innovative SR with CCUSの例として、COREX、FINEX、Hlsarna projectがある。<br />
                100% H2 DRIの例として、HYBRIT project、ArcelorMittal in Hamburgがある。<br />
                再エネ水素を一部に用いているDRIはCommercial DRIに分類されている。</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>水素利用見通し（化学）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openImageBox} data-name={"s02_2_1_4"}>
                  <img src={'../../images/chart/hydrogen-stacked-bar-grouped-2.png'} alt="" width={559} />
                </button>
                <ImageBox
                  open={this.state.s02_2_1_4}
                  close={this.closeImageBox}
                  name={"s02_2_1_4"}
                  src={'/images/chart/hydrogen-stacked-bar-grouped-2.png'}
                  alt="水素利用見通し（化学）"
                  width={1154}
                  height={554} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/energy-technology-perspectives-2020" target="_blank" rel="noreferrer noopener">IEA,”Energy Technology Perspectives 2020”</a>（2020年9月）</p>
                <p className={chartStyles.chartExplain}>石油化学一次産品の原料別生産量（世界）の実績、見通しを表示。<br />
                <br />
                SDS（Sustainable Development Scenario）：持続可能な開発シナリオ</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarStackGrouped id="carSale" chartData="carSale" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarStackGrouped id="transportation" chartData="transportation" width="100%" height="420px" />
            </div>
          </div>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarStacked id="fuelCellVehicle" chartData="fuelCellVehicle" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <MakerShare id="hydrogenStation" chartData="hydrogenStation" width="100%" height="420px" />
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`