import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import _ from "lodash"
import nl2br from "react-nl2br"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"

// markup
class BarStackGrouped extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData
    }
    
    this.series = [];
    this.filePath = "/csv/bar-stack-grouped/" // CSVデータ格納先
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    const chartConfig = this.getConfig()

    let root = am5.Root.new(this.props.id)
    let xLabel = chartConfig.axis[this.state.chartData][0].title
    let xRealName = chartConfig.axis[this.state.chartData][0].realName
    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }))
    chart.children.unshift(am5.Label.new(root, {
      text: chartConfig.title[this.state.chartData],
      fontSize: "12px",
      centerX: am5.percent(50),
      x: am5.percent(50)
    }))
    
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      fontSize: "11px"
    })
    
    // XY座標の設定
    // X座標
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: xLabel,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    // Y座標(左)
    let yAxisConfig = {
      min: chartConfig.axis[this.state.chartData][1].min,
      max: chartConfig.axis[this.state.chartData][1].max,
      numberFormat: chartConfig.axis[this.state.chartData][1].numberFormat,
      strictMinMax: true,
      calculateTotals: true,
      renderer: am5xy.AxisRendererY.new(root, {})
    }

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, yAxisConfig)
    );
    /*
    let rangeDataItem = yAxis.makeDataItem({
      value: chartConfig.axis[this.state.chartData][1].range
    })
    yAxis.createAxisRange(rangeDataItem)
    */
    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      fontSize: "11px"
    })
    yAxis.children.unshift(am5.Label.new(root, {
      rotation: -90,
      text: chartConfig.axis[this.state.chartData][1].title,
      y: am5.p50,
      centerX: am5.p50,
      fontSize: "11px",
    }))    

    // Y座標(右)
    if (typeof chartConfig.axis[this.state.chartData][2] !== 'undefined') {
      let yAxis2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: chartConfig.axis[this.state.chartData][2].min,
          max: chartConfig.axis[this.state.chartData][2].max,
          numberFormat: chartConfig.axis[this.state.chartData][2].numberFormat,
          syncWithAxis: yAxis,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true
          })
        })
      );
      /*
      let rangeDataItem2 = yAxis2.makeDataItem({
        value: chartConfig.axis[this.state.chartData][2].range
      })
      yAxis2.createAxisRange(rangeDataItem2)
      */
      let yRenderer2 = yAxis2.get('renderer')
      yRenderer2.labels.template.setAll({
        fontSize: "11px"
      })
      yRenderer2.grid.template.set("forceHidden", true);
      
      yAxis2.children.push(am5.Label.new(root, {
        rotation: -90,
        text: chartConfig.axis[this.state.chartData][2].title,
        y: am5.p50,
        centerX: am5.p50,
        fontSize: "11px",
      }))
      this.yAxis2 = yAxis2
    }

    // Legendの指定
    let legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(0),
      centerX: am5.percent(0),
      width: am5.percent(100),
      layout: root.gridLayout,
      clickTarget: "none"
    }))

    legend.labels.template.setAll({
      fontSize: (this.state.chartData === 'typeFuture') ? 9 : 11
    })
    legend.markers.template.setAll({
      width: (this.state.chartData === 'typeFuture') ? 10 : 14,
      height: (this.state.chartData === 'typeFuture') ? 10 : 14
    })

    // X座標のラベルを指定
    xRenderer.labels.template.setAll({
      text: "{realName}",
      fontSize: "11px"
    });

    // CSVファイルからデータの読み込み
    let fields = chartConfig.series[this.state.chartData].map((item) => { return item.name})
    let dataSource = `${this.filePath}${this.state.chartData}.csv`
    let chartType = this.state.chartData
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let newData = []
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      let processor = am5.DataProcessor.new(root, {
        numericFields: fields
      });
      processor.processMany(data);
      // グルーピング用にCSVデータを加工
      data.forEach((obj, id) => {
        newData[id] = {}
        for (let k in obj) {
          if (k === 'provider') {
            newData[id][k] = obj[k]
            newData[id]['category'] = `${obj['provider']} ${obj[xRealName]}`
            newData[id]['realName'] = obj[xRealName]
          } else {
            if (fields.includes(k)) {
              if (chartType === 'carSale') {
                // 自動車販売における駆動式構成比（世界）、輸送機関別エネルギー消費比率（世界）
                if (k === 'CO2原単位') {
                  newData[id][k] = obj[k]
                } else {
                  newData[id][k] = obj[k] * 100
                }
              } else if (chartType === 'transportation') {
                // 自動車販売における駆動式構成比（世界）、輸送機関別エネルギー消費比率（世界）
                if (k === 'CO2原単位') {
                  newData[id][k] = obj[k]
                } else {
                  newData[id][k] = obj[k]
                }
              } else {
                newData[id][k] = obj[k]
              }
            } else {
              newData[id][k] = obj[k]
            }
          }
        }
      })

      return newData
    })
    
    xAxis.data.setAll(data);

    this.xAxis = xAxis
    this.yAxis = yAxis

    this.chart = chart

    if (chartConfig.series[this.state.chartData].length === 1) {
      // シリーズの設定
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: chartConfig.axis[this.state.chartData][0].title,
        tooltip: am5.Tooltip.new(root, {
          labelText:"{valueY}"
        })
      }));
      series.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });
      series.columns.template.adapters.add("fill", (fill, target) => {
        return chartConfig.series[this.state.chartData][0].fill
      });
      
      series.columns.template.adapters.add("stroke", (stroke, target) => {
        return chartConfig.series[this.state.chartData][0].fill
      });
      series.data.setAll(data);
      series.appear();
    } else {
      chartConfig.series[this.state.chartData].forEach(item => {
        this.createSeries(data, legend, item.name, item.fill, item.type)
      })  
    }

    // Providerでグルーピング
    let byName = _.groupBy(data, 'provider')
    this.createProvider(byName)
        
  }

  // Providerの指定
  createProvider(data) {
    let xAxis = this.xAxis
    Object.keys(data).forEach((key) => {
      let range = xAxis.makeDataItem({})
      xAxis.createAxisRange(range)
      
      range.set("category", data[key][0].category);
      range.set("endCategory", data[key][data[key].length - 1].category)
      
      let label = range.get("label")
      label.setAll({
        text: data[key][0].provider,
        dy: 20
      })
      
      let tick = range.get("tick");
      tick.setAll({ visible: true, strokeOpacity: 0.3, length: 40, location: 0 });
    
      //let grid = range.get("grid");
      //grid.setAll({ strokeOpacity: 1 });
    })
  }

  createSeries(data, legend, name, fill, type) {
    // シリーズの設定
    let root = this.root
    let chart = this.chart
    let xAxis = this.xAxis
    let yAxis = this.yAxis
    let yAxis2 = this.yAxis2
    let categoryLabel = this.getConfig().axis[this.state.chartData][0].title

    if (type === 'bar') {
      let barTooltip = this.getConfig().axis[this.state.chartData][1].tooltip
      // 棒グラフの設定
      let seriesConfig = {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: name,
        categoryXField: categoryLabel
      }
      if (this.state.chartData === 'transportation') {
        seriesConfig.valueYShow = "valueYTotalPercent"
      }

      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, seriesConfig)
      );
      series.columns.template.setAll({
        tooltipText: barTooltip,
        fill: fill,
        stroke: fill,
        //width: am5.percent(38),
        tooltipY: am5.percent(5),
        templateField: "columnSettings"
      });
      series.data.setAll(data);
      series.appear();  
    } else {
      // 折線グラフの設定
      let lineTooltip = this.getConfig().axis[this.state.chartData][2].tooltip
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: (typeof yAxis2 === 'undefined') ? yAxis : yAxis2,
          valueYField: name,
          stroke: "rgba(0,0,0,0)",
          categoryXField: categoryLabel
        })
      );
      series.strokes.template.setAll({
        strokeWidth: 2,
        templateField: "strokeSettings",
        stoke: fill
      });
      series.data.setAll(data);
      
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            tooltipText: lineTooltip,
            strokeWidth: 2,
            stroke: fill,
            radius: 3,
            fill: root.interfaceColors.get("background")
          })
        });
      });  
      series.appear(); 
    }

    legend.data.setAll(chart.series.values);
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      this.root.dispose()
      this.setChartData()
    }
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allBarStackGroupedQuery {
            settings: allBarStackGroupedJson {
              edges {
                node {
                  axis {
                    production {
                      label
                      max
                      min
                      numberFormat
                      range
                      realName
                      title
                      type
                      tooltip
                    }
                    carSale {
                      label
                      max
                      min
                      numberFormat
                      range
                      realName
                      title
                      type
                      tooltip
                    }
                    transportation {
                      label
                      max
                      min
                      numberFormat
                      range
                      realName
                      title
                      type
                      tooltip
                    }
                    typeFuture {
                      label
                      max
                      min
                      numberFormat
                      range
                      realName
                      title
                      type
                      tooltip
                    }
                  }
                  series {
                    production {
                      fill
                      label
                      name
                      type
                    }
                    carSale {
                      fill
                      label
                      name
                      type
                    }
                    transportation {
                      fill
                      label
                      name
                      type
                    }
                    typeFuture {
                      fill
                      label
                      name
                      type
                    }
                  }
                  head {
                    production
                    carSale
                    transportation
                    typeFuture
                  }
                  title {
                    production
                    carSale
                    transportation
                    typeFuture
                  }
                  source {
                    carSale {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                    production {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                    transportation {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                    typeFuture {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2>{data.settings.edges[0].node.head[this.state.chartData]}</h2>
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）<a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText}</a>
              {data.settings.edges[0].node.source[this.state.chartData].sourceDate}
              </p>
              <p className={styles.chartExplain}>{nl2br(data.settings.edges[0].node.source[this.state.chartData].explain)}</p>
              <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}.csv`}>データダウンロード</a></p>
            </div>
          </>
        )}
      />
    )
  }
}
export default BarStackGrouped
